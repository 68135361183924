<template>
  <div>
    <div class="bg-el-dialog">
      <el-dialog
        width="31%"
        :visible.sync="AdPopup"
        ref="DetailForm"
        class="custbg-color sm-width-90 cust-head"
      >
        <template slot="title">
          <div class="flex gap-2">
            <feather-icon icon="BellIcon" class="" />
            <p class="sm-fsize12">Subscribe Now! For latest News & Updates</p>
          </div>
        </template>

        <Subscribepopup
          ref="reCaptcha"
          :ip="ip"
          @closeModal2="closeModal2"
          @closeModal="closeModal"
        />
      </el-dialog>
    </div>
    <div class="bg-el-dialog">
      <el-dialog
        title="Thank you for subscribing to our newsletter"
        width="40%"
        :visible.sync="isMessage"
        class="custbg-color trxtcolor sm-width-90"
      >
        <Message
          :message="message"
          :subMsg="subMsg"
          @closeModal3="closeModal3"
          @closeModal2="closeModal2"
        />
      </el-dialog>
    </div>
    <router-view />
  </div>
</template>

<script>
import Subscribepopup from "./views/home/components/Subscribepopup.vue";
import Message from "./views/home/components/Message.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: {
    Subscribepopup,
    Message,
  },
  data() {
    return {
      getBrandProfileData: "",
      AdPopup: false,
      ip: "",
      isMessage: false,
      message: "",
      subMsg:
        "We look forward to delivering valuable content straight to your inbox.",
    };
  },
  methods: {
    async getAllMenuShow() {
      await this.$store.dispatch("menu/getAllClientMenu");
    },
    async getBrandProfile() {
      let payload = {
        projectId: process.env.VUE_APP_ID,
        projectName: process.env.VUE_APP_COMMUNITY_NAME,
      };
      let data = await this.$store.dispatch(
        "brandProfile/getBrandProfile",
        payload
      );
      this.getBrandProfileData = data.data;
      if (this.getBrandProfileData.length) {
        this.primaryLogo =
          `${process.env.VUE_APP_STORAGE_URL_2}` +
          this.getBrandProfileData[0].primaryLogo;

        this.secondaryLogo =
          `${process.env.VUE_APP_STORAGE_URL_2}` +
          this.getBrandProfileData[0].secondaryLogo;
        // this.$store.commit(
        //   "brandProfile/UPDATE_PRIMARY_COLOR",
        //   this.getBrandProfileData[0].primaryColor
        // );
        let data = this.$cookies.get("primaryColor");
        this.$vs.theme({
          primary: data ? data : this.getBrandProfileData[0].primaryColor,
        });

        // this.$store.commit(
        //   "brandProfile/UPDATE_SECONDARY_COLOR",
        //   this.getBrandProfileData[0].secondaryColor
        // );
        this.$vs.theme({
          secondary: this.getBrandProfileData[0].secondaryColor,
        });

        // this.$store.commit(
        //   "brandProfile/UPDATE_TERTIARY_COLOR",
        //   this.getBrandProfileData[0].tertiaryColor
        // );
        this.$vs.theme({
          tertiary: this.getBrandProfileData[0].tertiaryColor,
        });
      }
    },
    async getIp() {
      axios
        .get("https://api.ipify.org?format=json")
        .then((res) => {
          this.ip = res.data.ip;
          this.getSub();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSub() {
      let payload = {
        ip: this.ip,
      };
      try {
        const state = await this.$store.dispatch(
          "admin/getSubscriber",
          payload
        );
        this.init();
        if (state.data.ip == this.ip) {
          if (
            this.$route.path == "/admin-login" ||
            this.$route.path == "/admin" ||
            this.$route.name == undefined ||
            this.$route.name == "undefined" ||
            this.$route.path == "/events-and-exhibitions"
          ) {
            this.AdPopup = false;
          } else {
            this.AdPopup = true;
          }
        }
      } catch (error) {
        console.log(error);

        this.$message({
          type: "error",
          message: error,
        });
        this.$vs.loading.close();
      }
    },
    async init() {
      let popVal = localStorage.getItem("pop");
      let val = Boolean(popVal);
      if (!val) {
        localStorage.setItem("pop", false);
      }
      if (val) {
        localStorage.setItem("pop", true);
        let pop = localStorage.getItem("pop");
        if (
          this.$route.path == "/admin-login" ||
          this.$route.path == "/admin" ||
          this.$route.name == undefined ||
          this.$route.name == "undefined" ||
          this.$route.path == "/events-and-exhibitions"
        ) {
          this.AdPopup = false;
        } else {
          this.AdPopup = Boolean(pop);
        }
      }
    },

    async closeModal() {
      localStorage.setItem("pop", false);
      this.AdPopup = false;
    },
    async closeModal2() {
      this.AdPopup = false;
      this.isMessage = true;
    },
    async closeModal3() {
      this.isMessage = false;
    },
  },
  async mounted() {
    this.getAllMenuShow();
    this.$vs.theme({
      primary: this.$cookies.get("primaryColor"),
    });
    setTimeout(async () => {
      await this.getBrandProfile();
    }, 500);
    this.getIp();

    let payload = { projectName: process.env.VUE_APP_COMMUNITY_NAME };
    let { data } = await this.$store.dispatch("cms/getClientCms", payload);

    await this.$store.dispatch("cms/getUser", payload);
  },
  computed: {
    ...mapState("admin", { doc: "downloadcatalogue" }),
    ...mapState("cms", ["pages", "user"]),
    ...mapState("project", ["projectDetails"]),

    ...mapState("admin", ["getprojects"]),
  },
};
</script>
<style>
.cust-head .el-dialog__headerbtn {
  top: 10px !important;
}
.cust-head .el-dialog__close {
  color: white !important;
}
.cust-head .el-dialog__header {
  background: rgba(var(--vs-secondary), 1) !important;
}
.cust-head .el-dialog {
  margin-top: 0 !important;
}
.trxtcolor .el-dialog__title {
  color: white !important;
}
.trxtcolor .el-dialog__close {
  color: white !important;
}
</style>
<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.g-cursor {
  &_hide {
    opacity: 0;
    width: 60px;
    height: 60px;
    transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    position: absolute;
  }

  &__circle {
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 0;
    position: fixed;
    width: 30px;
    height: 30px;
    border: 2px solid #e74747;
    border-radius: 100%;
    z-index: 5555;
    backface-visibility: hidden;
    transition: opacity 0.6s ease;
  }

  &__point {
    top: 0;
    left: 0;
    position: fixed;
    width: 10px;
    height: 10px;
    pointer-events: none;
    user-select: none;
    border-radius: 100%;
    background: #e74747;
    z-index: 55555555;
    backface-visibility: hidden;
    will-change: transform;
  }

  &_hover {
    .g-cursor__circle {
      opacity: 0;
      width: 60px;
      height: 60px;
      transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    }
  }
}
</style>
